// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';
import { Environment } from '@app/model/environment.model';
export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  serverUrl: '/',
  apiUrl: 'https://licenseesales.restaurant.api-dev.cfadevelop.com',
  proxyurl: 'https://cors-anywhere.herokuapp.com/',
  apiKey: '...',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  s3Url: 'https://s3.us-east-1.amazonaws.com/dev-cfabeyondtherestaurantdev-us-east-1-licensee-sales-upload/',
  clientId: '0oaizoljwkqaVeaoi0h7',
  issuer: 'https://cfahome.oktapreview.com/oauth2/ausc6e4sj2fnPnQ670h7',
  redirectUri: 'https://dev.cfalicenseesales.com/implicit/callback',
  scope: 'licensee-sales-portal:read',
  gtmId: 'G-3H94R4Q82N',
  lspReactAppUrl: "https://app.stsfcr-dev.cfadevelop.com/lsp-react"
} as Environment;
